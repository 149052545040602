div.specialPricingIcon {
  float: left;
  margin: 0 7px 7px 0;
  padding: 0;
}
div.specialPricingHdr {
  float: left;
  margin: 14px 7px 0 0;
  padding: 0;
  color: #CC0033;
  font-size: 11px;
  font-weight: bold;
}
div.specialPricingDescr {
  margin: 0px 7px 10px 0;
  padding: 0;
  color: #CC0033;
  font-size: 10px;
}
.specialPricingBlock {
  position: absolute;
  display: block;
  width: 300px;
  text-align: left;
  border: 1px solid #e0ddd7;
}
.specialPricingBlock .layerBody {
  width: 260px;
  background-color: #fff7e8;
  padding: 12px 20px 16px 20px;
  border: 1px solid #a3a09a;
}
.specialPricingBlock .layerBody .closeWindow {
  float: right;
}
.specialPricingBlock .layerBody .hdr {
  float: left;
  margin: 14px 7px 12px 0;
  padding: 0;
  color: #CC0033;
  font-size: 11px;
  font-weight: bold;
}
.specialPricingBlock .layerBody hr {
  border: 0;
  border-top: 1px solid #d6d6d6;
  padding: 0;
  margin: 0;
}
.specialPricingBlock .layerBody .icon {
  float: left;
  margin: 0 7px 7px 0;
  padding: 0;
}
.specialPricingBlock .layerBody .subHead {
  color: #ff0000;
  font: normal bold 12px Trebuchet MS;
  margin: 12px 0 0 0;
}
.specialPricingBlock .layerBody p,
.specialPricingBlock .layerBody div {
  margin: 10px 7px 10px 0;
  padding: 0;
  color: #666666;
  font-size: 10px;
}
.specialPricingNone {
  display: none;
}
.specialPricingStrikeOut {
  text-decoration: line-through;
}

