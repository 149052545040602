h1 {
	&.resortTitle  {
		font: normal normal 18px 'Lato', sans-serif;
		margin: 0;
		padding: 0;
		line-height: 22px;
		color: #002b66;
	}
}

.resort-detail-information-container {
	position: relative;
}

.resortDetailUrgentsHeader {
	font-family: 'Lato', sans-serif;
	font-size: 13px;
	color: #424242;
	letter-spacing: 1px;
	padding-bottom: 2px;
	margin-bottom: 10px;
	border-bottom: 1px solid #999999;
}

.resortDetailAllInclusiveFeesMoreInfo {
	font-weight: bold;
}

.resort-detail-nav-container {
	border-bottom: 1px solid #999999;
	margin: 15px 0 10px;
}
.resort-detail-sub-container {
	background-color: #FFFFFF;
	border: 1px solid #D4D4D4;
	padding: 20px 0px 30px 0px;

	& .nav{
		float:left;
		width: 134px;
	}

	& .content {
		float:left; 
		margin: 0 20px 0 30px;
		width: 260px;

		& .header {
			color: #13458a;
			font-family: Verdana;
			font-size: 14px;
			margin: 0px 0 4px 0;
		}
	}
}

.resort-detail-images {
	padding: 10px;
	border-top: 1px solid #c8c8c8;

	.resort-detail-image {
		width: 212px;
		height: 170px;

		&:first-child {
			margin-left: 0;
		}
		margin-left: 22px;
	}
}

.resort-detail-multimedia-container {
	width: 446px;
	padding: 0 10px;
	margin-bottom: 10px;

	.resort-detail-multimedia-header {
		width: 436px;
		background-color: #3c3c3c;
		margin-bottom: 5px;
		padding-left: 10px;
		font-family: Arial;
		font-size: 11px;
		line-height: 1.9em;
		color: #ffffff;
		font-weight: bold;
	}

	.resort-detail-play-video-container {
		.row();
		width:223px;
	}

	.resort-detail-play-video-details {
		padding-left: 10px
	}

	.resort-detail-play-video-title {
		font-family: Arial;
		font-size: 12px;
		color: #666666;
		font-weight: bold;
		margin: 5px 0 10px 0;
	}

}

.video_player-popup-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000000;
	opacity: .75;
	filter: alpha(opacity=75);
	z-index: 12000;
}

.video_player-popup-container {
	position: fixed;
	width: 636px;
	min-height: 416px;
	border: solid 1px #c8c8c8;
	background-color: #fafafa;
	padding: 20px;
	z-index: 13000;
	top: 25%;
	left: 50%;
	margin-left: -318px;
	text-align: left;

	.video_player-close-button-container {
		position: relative;

		.video_player-close-button {
			position: absolute;
			right: -10px;
			top: -10px;
			cursor: pointer;
		}
	}

	.video_player-title {
		margin-bottom: 10px;
	}

	.video_player-video-embed-html {
	}
}

.inventory-nav-container {
	margin: 0; 
}
.inventory-container {
	position: relative;
 
	div {
		font-size: 10px;
	}
}

.rdShare {
		background-color: #e0e0e0;
		height: 20px;
		padding: 30px 20px;
		margin: -20px -15px 25px -15px;
}
.srdTabText, .tddTabText {
		font: normal bold 9px arial;
		color: #13458a;
		margin: 3px 10px 3px 10px;
		text-transform: uppercase;
}
.srdTabTextNoPad, .tddTabTextNoPad {
		font: normal bold 9px arial;
		color: #13458a;
		margin: 3px 0px 3px 0px;
		text-transform: uppercase;
}