.view-hold {

	.unit-summary-container {
		margin-top: 0px;
	}

	.unit-summary-table {
		margin-top: 0px;
	}

}
