.explore-resorts-container {

	margin: 0 0 20px 0;

	.explore-subheader {
		font-family: Arial;
		font-size: 17px;
		font-weight: bold;
		color: #666666;
		margin: 10px 0;
		padding: 0;

		.alert {
			color: #ff0000;
		}
	}

	.box {
		width: 854px;
		border: 1px solid #c8c8c8;
		padding: 20px;
		margin: 10px 0px;
		#gradient .vertical(#ffffff,#dbdbdb);

		&.small {
			padding-bottom: 18px;
			margin: 0;
			#gradient .vertical(#fdfdfd,#c8c8c8);
		}
	}

	.select-benefit-container > * + * {
		margin-left: 20px;
	}

	#loader {
		background: url("//d25ckoihpsjafc.cloudfront.net/rcitrials-assets/img/loader_clock.gif") no-repeat;
		width: 20px;
		height: 20px;
	}

	.column-header-container {
		border-bottom: 1px solid #b4babd;
		min-height: 105px;
	}

	h1.expResortsHeader {
		font-family: Arial;
		font-size: 26px;
		color: #002b66;
		line-height: 20px;
		padding: 35px 0px 0px 0px;
		margin: 0;
	}
	.expResortsSubHeader {
		font-family: Arial;
		font-size: 20px;
		color: #13458a;
		line-height: 20px;
		margin: 0;
		padding: 0;
	}
	.expResortsInputLarge {
	    width: 270px;
	    height: 12px;
	    font-size: 10px;
	}
	.expResortsInput {
	    width: 160px;
	    height: 12px;
	    font-size: 10px;
	}
	.expResortsInputSmall {
	    width: 110px;
	    height: 12px;
	    font-size: 10px;
	}
	.expResortsBoxStandard {
		max-width: 150px;
		min-width: 70px;
	}
	.expResortsDateBox {
		width: 80px;
		height: 15px;
	}
	.expResortsLabel {
		font-family: Arial;
		font-size: 14px;
		color: #13458a;
		padding: 0px;
		margin: 0px 0px 12px 0px;
	}
	.expResortsLabelMiddle {
		font-family: Arial;
		font-size: 14px;
		color: #13458a;
		padding: 0px;
		margin: 0px 0px 7px 7px;
	}
	.errMessage {
		color: #ff0000;
		font-size: 10px;
		margin-bottom: 12px;
	}

	/* Advanced Search Interface Classes */
	#overlayDivOne {
		width: 250px;
	}
	#overlayDivTwo {
		width: 594px;
	}
	.overlayDiv {
		position: absolute;
		height: 520px;
		top: 0px;
		left: 0px;
		opacity: 0;
		filter: alpha(opacity=0);
		z-index: 1000;
		background-color: #999999;
	}
	.sectionDisabled {
		opacity: 0.4;
		filter: alpha(opacity=40);
	}
	#stepOne {
		width: 250px;
		margin: 0px;
		padding: 0px;
		position: relative;
	}
	#selectableItemsArea {
		margin: 20px 0px 0px 0px;
	}
	#stepOneHalf {
		width: 332px;
		margin-top: 30px;
	}
	#searchDoubleArrow {
		width: 42px;
		height: 35px;
		background: url("//d25ckoihpsjafc.cloudfront.net/rcitrials-assets/img/exploreResorts/double_arrow.gif") no-repeat;
		margin: 0px;
		padding: 0px;
	}
	#searchSelectedColumn {
		margin: 0px 0px 0px 10px;
		padding: 0px;
		width: 250px;
	}
	#selectedItemsArea {
		margin: 20px 0px 0px 0px;
	}
	#additionalOptions {
		width: 594px;
		position: relative;
	}
	#stepTwo {
		width: 262px;
	}
	#optionItemsArea {
		margin: 20px 0px 0px 0px;
	}
	#expResortsBenefitSelect,
	#expResortsRegionSelect,
	#expResortsDestinationSelect {
		text-transform: uppercase;
		width: 194px;
		height: 20px;
	}
	.expResortsSelectContainer {
		margin: 0px 0px 10px 0px;
		padding: 0px;
	}
	.expResortsGrayBar {
		background: #cedde4 url("//d25ckoihpsjafc.cloudfront.net/rcitrials-assets/img/exploreResorts/resultBar.gif") no-repeat;
		width: 250px;
		min-height: 22px;
		border-bottom: 1px solid #b7c1c5;
	}
	.expResortsResultContainer {
		width: 190px;
		padding: 5px 8px;
		margin: 0px;
		float: left;
	}
	.expResortsResultLabel {
		font-family: Arial;
		font-size: 12px;
		color: #454545;
		margin: 0;
		padding: 0;
		line-height: 15px;
	}
	.expResortsResultLabelOff {
		font-family: Arial;
		font-size: 12px;
		color: #999999;
		margin: 0;
		padding: 0;
		line-height: 15px;
	}
	.expResortsResultControlAdd {
		background: url("//d25ckoihpsjafc.cloudfront.net/rcitrials-assets/img/exploreResorts/btn_add.png") no-repeat;
		width: 15px;
		height: 15px;
		margin: 5px 8px 3px 0px;
		float: right;
		cursor: pointer;
	}
	.expResortsResultControlContainer {
		float: right;
		width: 40px;
	}
	.expResortsResultControlDetails {
		background: url("//d25ckoihpsjafc.cloudfront.net/rcitrials-assets/img/exploreResorts/btn_resortsearch.png") no-repeat;
		width: 15px;
		height: 15px;
		margin: 5px 5px 3px 0px;
		float: left;
		cursor: pointer;
	}
	a.resortDetailAnchor {
		display: block;
		width: 100%;
		height: 100%;
	}
	.expResortsResultControlRemove {
		background: url("//d25ckoihpsjafc.cloudfront.net/rcitrials-assets/img/exploreResorts/btn_remove.png") no-repeat;
		width: 15px;
		height: 15px;
		margin: 5px 5px 3px 0px;
		float: right;
		cursor: pointer;
	}
	#resortAutocompleteBox {
		width: 250px;
	}
	#resortAutocompleteDropdown {
		background: transparent url("//d25ckoihpsjafc.cloudfront.net/rcitrials-assets/img/exploreResorts/autocomplete_area.png") no-repeat bottom left;
		width: 250px;
		margin: 0px;
		padding: 0px;
		overflow: hidden;
		max-height: 340px;
	}
	#resortAutocompleteResults {
		width: 242px;
		padding: 0px;
		margin: 0px 4px 10px 4px;
		overflow: hidden;
		max-height: 330px;
	}
	.resortListItem {
		width: 230px;
		color: #000000;
		padding: 0px 6px;
		margin: 1px 0px 1px 0px;
		cursor: pointer;
	}
	.resortListGrayItem {
		width: 230px;
		color: lightgray;
		padding: 0px 6px;
		margin: 1px 0px 1px 0px;
		cursor: pointer;
	}
	.resortListItem:hover, .resortListGrayItem:hover {
		background-color: #485ef5;
		color: #ffffff;
		cursor: pointer;
	}
	#selectedDestinations {
		margin: 0px 0px 20px 0px;
	}
	#moreOptionsColumn {
		border-left: 1px solid #c8c8c8;
		padding: 0px 0px 35px 25px;
		width: 240px;
		margin: 0px;
	}
	.activeHoldRow {
		background-color: #fbe0c6;
	}
	.modalHeader {
		font-family: Arial;
		font-size: 26px;
		color: #13458a;
		letter-spacing: 0px;
		padding: 0px;
		margin: 0px;
	}
	.modalLine {
		margin: 20px 0px 10px 0px;
		padding: 0px;
	}
	#circleOne {
		margin: 0;
		padding: 0;
		width: 30px;
		height: 30px;
		background: url("//d25ckoihpsjafc.cloudfront.net/rcitrials-assets/img/exploreResorts/circle1.png") no-repeat;
	}
	#circleTwo {
		margin: 0px 0px 0px 25px;
		padding: 0;
		width: 30px;
		height: 30px;
		background: url("//d25ckoihpsjafc.cloudfront.net/rcitrials-assets/img/exploreResorts/circle2.png") no-repeat;
	}
}
