.my-benefits-container {
	margin-top: 10px;

	.benefit {
		margin: 0 0 20px 20px;
		width: 216px;

		&:first-child {
			margin-left: 0;
		}

		.benefit-thumbnail-container {
			width: 216px;
			height: 125px;

			.benefit-thumbnail {
				max-width: 100%;
				max-height: 100%;
			}
		}

		.benefit-information-container {
			min-height: 110px;
		}

		.benefit-name {
			margin: 7px 0;
		}

		.benefit-description {
			margin: 0 0 10px 0;
		}

		.benefit-status {
			margin: 0 0 3px 0;
		}

		.benefit-expiration {
			margin: 0 0 10px 0;
		}
	}
}

.benefits-summary-container {

	.box {
		padding: 15px 15px 25px 15px;
		margin: 10px 0 0 0;

		h2 {
			font-size:	20px;
			margin: 0 0 12px 0;
		}
	}

	.summary-header-container {
		display: table;
		margin-bottom: 7px;
	}

	.summary-header-row {
		display: table-row;

		.column {
			display: table-cell;
			vertical-align: bottom;
			border-bottom: 1px solid #c8c8c8;
		}
	}

	.summary-container {
		display: table;
	}

	.summary-row {
		display: table-row;

		.column {
			display: table-cell;
			vertical-align: top;
		}
	}

	.column {
		padding-right: 10px;
		padding-bottom: 3px;
	}

	.available-benefits {
		max-width: 311px;
		min-height: 150px;

		.col1 {
			width: 222px;
		}
		.col2 {
			width: 69px;
		}
	}

	.expired-benefits {
		max-width: 311px;
		min-height: 150px;
		margin-left: 12px;

		.col1 {
			width: 222px;
		}
		.col2 {
			width: 69px;
		}
	}

	.held-reservations,
	.current-reservations,
	.canceled-reservations {
		min-height: 150px;
		.col1 {
			width: 130px;
		}
		.col2 {
			width: 120px;
		}
		.col3 {
			width: 167px;
		}
		.col4 {
			width: 87px;
		}
		.col5 {
			width: 130px;
			padding-right: 0;
		}
	}
}

.benefit-container {
	margin-top: 20px;

	.benefit-intro-container {
		padding-bottom: 20px;
		border-bottom: 1px solid #c8c8c8;
		margin-bottom: 20px;
	}

	.benefit-details {
		margin: 0  0 0 20px;
		width: 444px;

		.btn {
			margin-top: 20px;
		}

		.label-container {
			margin-right: 30px;
		}

		.detail,
		.label {
			margin-bottom: 3px;
		}
	}
}
