.review-reservation-cancellation {
	

	.unit-summary-container {
		margin-top: 0px;
	}

	.unit-summary-table {
		margin-top: 0px;
	}

	.confirm-cancellation-container {
		margin-top: 25px;
	}

}
