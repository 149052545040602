h1 {
	&.mlabel {
		font: normal normal 20px 'Lato', sans-serif;
		margin: 0;
		padding: 0 0 8px 0;
		line-height: 23px;
		color: #002b66;
	}
}

.inventory-buttons-container {
	background-color: #d0d0d0;
	padding: 7px 6px;
	border-radius: 0px 0px 7px 7px;
	text-align: right;
	margin-bottom: 4px;

	.btn {
		&:first-child {
			margin-left: 0;
		}
		margin-left: 10px;
	}
}

.resort-image-positioner {
	position: relative;
}

.resort-image {
	width: 160px;
	margin: 0 20px 0 0;
	padding: 0px;
	position: relative;
}

.resort-badge {
	position: absolute;
	bottom: 5px;
	left: 5px;
}

.search-results-sortby-container {
	margin:	6px 0 9px 0;

	.search-results-links-container {
		width: 600px;
	}
}

.search-pagination-container {
	margin: 6px 0 3px 0;
	text-align:	right;

	.search-results-links-container {
		margin: 0 0 -4px 0;
	}
}

.search-results-links-label {
	max-width: 91px;
	margin-right: 7px;
	display: inline-block;
	line-height: 16px;
	text-align: left;

	&:after {
		content: ":";
	}
}

.search-results-links-container {
	.row();

	list-style: none;
	margin: 0;
	padding: 0;
	display: inline-block;
	line-height: 16px;

	.search-results-link-container {
		position: relative;
		margin: 0 0 0 14px;

		&:first-child {
			margin-left: 0;

			&:before {
				content: none;
				margin: 0;
			}
		}
		&:before {
			content: "";
			display: block;
			background-color: #498ce7;
			position: absolute;
			width: 1px;
			top: 3px;
			bottom: 3px;
			left: -7px;
		}

		.search-results-link {
			color: #498ce7;  
			text-decoration: none;
			cursor: pointer;
			text-align: left;

			&:hover {
				text-decoration: underline;
			}

			&.on {
				font-weight: bold;
				cursor: default;

				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}

.search-results-container {
	position: relative;

	> .resort-search-results + .resort-search-results {
		margin-top: 10px;
	}

	h3 {
		&.noResortFound {
			margin: 0 0 20px 0;
			line-height: 18px;
			color: #ee2600;
		}
	}
}

.resort-search-results {
	#gradient .vertical(#ffffff,#e5e5e5);
	border: solid 1px #c8c8c8;
	padding: 10px;

	&.featured {
		#gradient .vertical(#feefcb,#ffffff);
		border: solid 1px #dadcd1;
	}

	.resort-details {
		.clearfix();
		width: 495px;

		.resort-info {
			width: 340px;
			float: left;

			.resort-title {
				font-size: 16px;
				line-height: 17px;
				margin: 0;
			}

			.resort-location {
				font-size: 12px;
				line-height: 14px;
				margin: 0;
				color: #13458a;
			}
		}

		.resort-rating {
			float: right;
		}

		.resort-highlights {
			font-size: 10px;
			margin: 10px 0;
		}

		.resort-learn-more {
			float: right;
			margin: 10px 6px 0 0;
		}
	}
}

.resort-availability-container {
	color: #646260;
	font-size: 10px;
	font-family: Verdana;

	.inventory-results-header {
		background-color: transparent;
		border-top: 1px solid #c8c8c8;
		border-bottom: 1px solid #c8c8c8;
		padding-bottom: 7px;

		.title {
			margin-top: 6px;
			font-weight: bold;
		}

		.btn {

			&.help {
				margin: 5px 0 0 20px;
				font-size: 9px;
				padding: 0 5px;
			}
		}

		.helper-container {
			background-color: #fafafa;
			margin-left: 19px;
		} 
	}

	.inventory-availability-container {
		margin: 4px 0;
	}

	.inventory-special-pricing-title {
		background-color: #fafafa;
		color: #CC0033;
		padding: 6px 7px 3px 7px;
	}

	.inventory-details-container {
		background-color: #fafafa;
		padding: 4px 0;
		vertical-align: top;
	}

	.inventory-occupancy-kitchen {
		width: 145px;
		padding: 0 7px;

		&.special-pricing {
			width: 125px;
		}
	}

	.inventory-dates {
		width: 185px;
		padding-right: 7px;

		&.special-pricing {
			width: 145px;
		}

		.inventory-calendar-container {
			margin-right: 5px;
		}
	}

	.inventory-pricing {
		width: 137px;
		padding-right: 7px;

		&.special-pricing {
			width: 197px;

			.regular-price,
			.regular-per-night-price {
				color: #646260;
				text-decoration: line-through;
			}
		}

		.regular-price {
			color: #ff0000;
		}

		.special-pricing-price {
			margin-left: 20px;
			color: #cc0033;
		}
	}

	&.resort-detail {

		.inventory-results-header {
			background-color: #ffffff;
		}

		.inventory-occupancy-kitchen {
			width: 140px;

			&.special-pricing {
				width: 105px;
			}
		}

		.inventory-dates {
			width: 180px;

			&.special-pricing {
				width: 143px;
			}
		}

		.inventory-pricing {
			width: 118px;

			&.special-pricing {
				width: 190px;
			}
		}
	}
}

.resort-property-ratings-popup-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000000;
	opacity: .75;
	filter: alpha(opacity=75);
	z-index: 10000;
}

.resort-property-ratings-popup-container {
	position: fixed;
	width: 636px;
	border: solid 1px #c8c8c8;
	background-color: #fafafa;
	padding: 20px;
	z-index: 11000;
	top: 25%;
	left: 50%;
	margin-left: -318px;
	text-align: left;

	.resort-property-ratings-close-button-container {
		position: relative;

		.resort-property-ratings-close-button {
			position: absolute;
			right: -10px;
			top: -10px;
			cursor: pointer;
		}
	}

	.resort-property-ratings-message {
		margin-bottom: 20px;
	}

	.resort-property-ratings-rating-container {
		.row();

		margin-bottom: 20px;
	}

	.resort-property-rating-image {
		margin-right: 20px;
		width: 120px;
	}

	.resort-property-rating-description {
		width: 496px;
	}
}

.resort-property-ratings-open-container {
	position: relative;
}
