.the-borderlands {
	width: 950px;
	text-align: left;
}

.the-site {
	min-height: 330px;

	.site-banner {
		width: 950px;
		min-height: 1px;
		margin-top: -5px;

		& + .the-content {
			margin-top: 2px;

			&.two-col {

				.col-1 {
					margin-top: 0;
				}
				.col-2 {
					padding-top: 20px;
					margin-top: 0
				}
			}
		}
	}

	.the-content {

		.clearfix();

		> * {
			float: left;
			margin: 0;
			padding: 0;
		}

		&.one-col {

			.col-1 {
				background: #EBEBEB;
				width: 894px;
				padding: 30px 28px;
				margin-top: -5px;
			}
		}

		&.two-col {
			background-image: url("//d25ckoihpsjafc.cloudfront.net/rcitrials-assets/img/bg_the_content_two_col.gif");
			background-repeat: repeat-y;
			background-position: 212px;

			.col-1 {
				width: 210px;
				margin: 2px 2px 0 0;
			}
			.col-2 {
				background-color: #ebebeb;
				width: 698px;
				padding: 25px 20px 30px;
				margin-top: -5px;
				min-height: 280px;
			}
		}

		&.three-col {
			background-image: url("//d25ckoihpsjafc.cloudfront.net/rcitrials-assets/img/bg_the_content_three_col.gif");
			background-repeat: repeat-y;
			background-position: 212px;

			.col-1 {
				width: 210px;
				margin: 2px 2px 0 0;
			}
			.col-2 {
				background: #ebebeb;
				width: 466px;
				padding: 5px 24px 30px 24px;
				margin-top: -5px;
				min-height: 256px;
			}
			.col-3 {
				width: 194px;
				background-color: #d4d4d4;
				padding: 25px 15px 20px;
				margin-top: -5px;
			}
		}

		&.home {
			.col-1 {
				background-color: transparent;
			}
		}
	}
}

.outage-banner-container {
	width: 950px;
	min-height: 54px;
	#gradient .vertical(#de503d,#d31a01);
	position: relative;
	margin-top: -4px;

	& + .site-banner {
		margin-top: 0;
		border-top: 2px solid #666666;
	}

	&:before {
		position:absolute;
		content: url("//d25ckoihpsjafc.cloudfront.net/rcitrials-assets/img/outage_alert_icon.png");
		left: 20px;
		top: 17px;
	}

	.outage-message {
		color: #ffffff;
		margin: 0;
		padding: 15px 35px 10px 75px;
	}

}

/* EVS1 Response Mimicking Active Banner Styles */
.evs1-mimicking-active {
	text-align: center;
	background-color: #f00;
	padding: 20px 0;
	position: relative;
	z-index: 100;

	p {
		color: #fff;
		font-size: 25px;
		margin: 0;
	}
}
