.rys-title-header {
		font-size: 16px;
		color: #d31900;
		margin-bottom: 12px;
}

.rys-menu-open-container {

	#gradient .vertical(#ffffff,#dbdbdb);
	width: 661px;
	height: 29px;
	padding: 12px 15px 0px 20px;
	border: 1px solid #c8c8c8;
}

.rys-menu-container {
	#gradient .vertical(#ffffff,#dbdbdb);
	width: 656px;
	height: 193px;
	border: 1px solid #c8c8c8;
	margin: 0;
	padding: 12px 20px 15px 20px;

	h4 {
		font-size: 12px;
		margin: 0 0 8px 0;
		color: #d31900;
		text-transform: uppercase;
	}

	.rys-col1 {
		width: 225px;
		min-height: 120px;
	}
	.rys-col2 {
		width: 165px;
		min-height: 120px;
		border-left: 1px solid #c8c8c8;
		border-right: 1px solid #c8c8c8;
		padding: 0px 20px;
		margin: 0px 20px;
	}
	.rys-col3 {
		width: 184px;
		min-height: 120px;
	}
}

.rys-explore-resorts-active-container {

	.rys-explore-resorts-date-label {
		&:first-child {
			margin-top: 0;
		}
		margin-top: 15px;
	}
}

.rys-preferred-checkin-container {

	.rys-preferred-checkin-label {
		font-size: 10px;
		font-weight: bold;
		margin-bottom: 7px;
	}

	.rys-preferred-checkin-field {
		width: 160px;
	}

	.rys-preferred-checkin-format {
		margin: 2px 0 0 2px;
		font-style: italic;
	}
}

.rys-inventory-options-container {

	.rys-inventory-options-field-container {
		.row();
		margin-bottom: 5px;
	}

	.rys-inventory-options-radio-button {
		margin-right: 8px;
	}

	.rys-inventory-options-label {
		margin-top: 2px;
	}

}

.rys-menu-buttons {
	margin-top: 20px;

	.rys-resort-count {
		font-weight: bold;
	}
}

.rys-menu-open-header {
	.clearfix();

	.rys-title-header {
		float: left;
	}

	.rys-menu-open-button {
		float: right;
	}
}

.rys-menu-header {
	.clearfix();

	.rys-title-header {
		float: left;
	}

	.rys-menu-close-button {
		float: right;
		cursor: pointer;
	}
}

.rys-other-criteria-toggle-container {
	margin-bottom: 5px;
}

.rys-criteria-popup-container {
	position: relative;
}

.rys-criteria-popup {
	position: absolute;
	width: 656px;
	border: solid 1px #c8c8c8;
	background-color: #fafafa;
	.box-shadow(4px 4px 4px -2px #666666);
	padding: 10px 10px 15px 10px;
	right: -11px;
	top: 7px;
	z-index: 2000;

	.rys-criteria-close-button-container {
		position: relative;

		.rys-criteria-close-button {
			position: absolute;
			right: 0;
			cursor: pointer;
		}
	}

	.rys-criteria-title {
		font-size: 23px;
		margin: 0 0 10px 0;
	}

	.rys-criteria-message {
		border-bottom: 1px solid #c8c8c8;
		padding-bottom: 10px;
	}

	.rys-criteria-popup-buttons {
		margin-top: 4px;
	}

	.rys-criteria-options-container {
		width: 656px;
		margin-top: 10px;

		.rys-criteria-option-container {
			width: 328px;
			margin-bottom: 3px;

			&.rys-criteria-option-container-ocrating {
				width: 500px;
				margin-bottom: 7px;

				.rys-criteria-option-checkbox-container {
					margin-top: 7px;
				}

				.rys-criteria-option-label-container {
					width: 200px;
					margin: 10px 0 0 7px;

					.rys-criteria-count {
						color: #ee2600;
					}
				}
			}
		}

		.rys-criteria-option-checkbox-container {
			width: 20px;
		}

		.rys-criteria-option-label-container {
			width: 298px;
			margin: 2px 10px 0 0;

			.rys-criteria-count {
				color: #ee2600;
			}
		}
	}
}

.rys-toggle-btn {
	position: relative;
	cursor: pointer;
	padding-left: 15px;
	margin-right: 5px;

	&:before {
		position: absolute;
		content: url("//d25ckoihpsjafc.cloudfront.net/rcitrials-assets/img/plus_symbol.gif");
		left: 0;
	}
}
