.tab-container {
		display: table;
		border-collapse: separate;
		border-spacing: 1px 0;
		margin: 0 -1px;

		.tab-container-row {
				display: table-row;
		}

		// default layout direction is row (horizontal)

		.tab {
				cursor: pointer;
				color:@tabTextColor;
				display: table-cell;
				vertical-align: middle;
				text-decoration: none !important;
				text-transform: capitalize;
				font-family: 'Lato',sans-serif;
				font-size: 12px;
				text-align: center;
				line-height: 1.2em;
				padding: 5px 10px;
				margin: 0 1px 0 0;
				#gradient .vertical(@tabGradientStart,@tabGradientEnd);
				border-radius: 7px 7px 0 0;
				border: 1px solid @tabBorderColor;
				border-bottom-width: 0;
				.box-shadow(3px 1px 6px -3px #999999);

				&.on,
				&:hover {
						color: @tabOnTextColor;
						border-color: @tabOnBorderColor;
						#gradient .vertical(@tabOnGradientStart,@tabOnGradientStart);
				}

				&.on {
						cursor: default;
				}

				&.uppercase {
						text-transform: uppercase;
				}
		}

		&.column {
				display: block;
				margin: 0;

				.tab-container-row {
						display: block;
				}

				.tab {
						display: block;
						text-align: left;
						padding: 8px 10px 10px;
						border-radius: 0px 7px 7px 0px;
						border-width: 1px;
						border-left-width: 0;
						margin-bottom: 5px;
				}
		}

		&.large {
				.tab {
						font-size: 13px;
						height: 34px;
						padding: 0px 12px;
				}
		}
}
