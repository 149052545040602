.edit-traveler-information-step1 {

	.booking-info-form-container {
		padding: 8px 0 8px 0;
		margin-bottom: 8px;
		border-bottom: 1px solid #c8c8c8;

		&.select-traveler-info {
			margin-bottom: 0px;
			border-top: 1px solid #c8c8c8;
		}
	}
}

.edit-traveler-information-step2 {

}

.name-change-fee-message {
	color: #d31900;
	font-weight: bold;
	text-transform: uppercase;
	margin-top: 15px;
}

.traveler-info-changes-summary-container {

	.booking-buttons-container {
		margin: 10px 0;
	}

	.info-container {
		&:first-child {
			margin-top: 0;
		}
		margin-top: 10px;

		& .secondaryPhone {
			margin-top: 5px;
		}

		.btn {
			&:first-child {
				margin-top: 5;
			}
			margin-top: 10px;
		}
	}

	.traveler-info-changes-summary-table {
		color: #646260;
		font-size: 10px;
		font-family: Verdana;
		margin: 0;
		padding: 0;
		border-spacing: 0;
		border-collapse: collapse;

		.traveler-info-changes-summary-confirmation-number {
			> th {
					border-top: 1px solid #c8c8c8;
					padding-top: 5px;
					padding-bottom: 5px;
					vertical-align: bottom;
			}
		}

		.traveler-info-changes-summary-header {
			> th {
					border-top: 1px solid #c8c8c8;
					border-bottom: 1px solid #c8c8c8;
					padding-top: 5px;
					padding-bottom: 5px;
					vertical-align: bottom;
			}
		}

		td {
			padding: 4px 0;
			border-bottom: 1px solid #c8c8c8;
			vertical-align: top;
		}

		.cellNewTravInfo {
			width: 193px;
			padding-right: 7px;
		}

		.cellPrevTravInfo{
			width: 220px;
			padding-right: 7px;
		}

		.cellTotalLabels{
			width: 175px;
			padding-right: 7px;
		}

		.cellPrice {
			width: 65px;
			padding-right: 7px;
			text-align: right;
		}

		.cellCurrency {
			width: 47px;
			text-align: left;
		}
	}

	.btn {
		margin: 1px 0;
	}

	.name-change-fee-override-container {
		margin-top: 6px;

		> * {
			margin-right: 10px;
		}

		.name-change-fee-override-label {
			width: 105px;
		}
	}

	.name-change-fee-override-field {
		width: 90px;
		height: 12px;
	}

	.name-change-fee-override-tagline {
		font-size: 10px;
		margin: 4px 5px 4px 115px;
	}

	.formlabel {
		font-weight: bold;
		margin-top: 2px;
	}
}