.lt-ie10 {

    .navPriTopContainer {
        background-color: mix(@navPriTopAreaGradientStart,@navPriTopAreaGradientEnd, 60%);
        background-image: none;
        filter: none;
    }

    .searchWidgetMonth {
        background-color: mix(@searchWidgetMonthGradientStart,@searchWidgetMonthGradientEnd, 60%);
        background-image: none;
        filter: none;

        &:hover, &.current {
            background-color: mix(@searchWidgetMonthOnGradientStart,@searchWidgetMonthOnGradientEnd, 60%);
            background-image: none;
            filter: none;
        }

        &.disabled {
            background-color: mix(@searchWidgetMonthDisabledGradientStart,@searchWidgetMonthDisabledGradientEnd, 60%);
            background-image: none;
            filter: none;
        }
    }

    .tab-container {
        .tab-container-row {
            .tab {
                background-color: mix(@tabGradientStart,@tabGradientEnd, 60%);
                background-image: none;
                filter: none;

                &.on,
                &:hover {
                    background-color: mix(@tabOnGradientStart,@tabOnGradientStart, 60%);
                    background-image: none;
                    filter: none;
                }
            }
        }
    }
}
