#homeSlideshowContainer {
  width: 950px;
  height: 215px;
  position: relative;
}
#homeSlideshowSlidesContainer {
  width: 950px;
  height: 215px;
  background-color: #FFF;
	-moz-box-shadow:    inset 0 0 3px #666666;
	-webkit-box-shadow: inset 0 0 3px #666666;
	box-shadow:         inset 0 0 3px #666666;
}
.homeSlideshowSlide {
  width: 950px;
  height: 215px;

  a { 
    display: block;
    width: 100%;
    height: 100%;
  }
}

.homeSlideshowControl {
  background-color: #CCC;
  background-color: rgba(255,255,255,0.5);
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  margin-right: 4px;
  cursor: pointer;
  border: 1px solid #647882;
  -moz-box-shadow: 0px 0px 2px #aaa;
  -webkit-box-shadow: 0px 0px 2px #aaa;
  box-shadow: 0px 0px 2px #aaa;
	zoom:1; 
	*display: inline;
	*background-color: #cccccc;
	*filter: Alpha(Opacity=60);
}
.homeSlideshowControl.basicSlideshowControlOn {
  background-color: #FFF;
	border: 1px solid #163F53;
  -moz-box-shadow: 0px 0px 2px #888;
  -webkit-box-shadow: 0px 0px 2px #888;
  box-shadow: 0px 0px 2px #888;
	*filter: Alpha(Opacity=100);
}
.homeSlideshowControl p,
.homeSlideshowControl.basicSlideshowControlOn  p {
	font-family: Arial;
  line-height: 20px;
  width: 100%;
  text-align: center;
  color: #647882;
  font-size: 11px;
  margin: 0;
  padding: 0;
}
.homeSlideshowControl.basicSlideshowControlOn  p {
  color: #163F53;
}
#homeSlideshowControlsContainer {
  position: absolute;
  bottom: 12px;
  left: 20px;
}