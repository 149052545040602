.popUpCalendarContainer {
	position:absolute;
	z-index: 1000;
}

#popupCalendar {
	border: 0;
	text-align: left;
	margin: 0;
	padding: 0;
	font: normal normal 9px Verdana;
	color: #646260;
}

#popupCalendar div#header {
	#gradient .vertical(#f2f2f2,#ffffff);
	padding:0;
	margin: 0;
	border: 1px solid #CCCCCC;
}

#popupCalendar div#header p {
	text-align: center;
	margin: 8px;
	font: 1.2em Verdana, Sans-Serif;
	letter-spacing: 1px;
	color: #000;
}

#popupCalendar table#monthGridDays {
	margin: 0;
	padding: 0px;
	background: none;
	border: 0;
}

#popupCalendar table#monthGridDays td {
	text-align: center;
	vertical-align: middle;
	background: none;
	width: 24px;
	font: 900 1em/1.4em Verdana, Sans-Serif;
	color: #000;
	border: 0;
	padding: 2px;
}

#popupCalendar table#monthGrid {
	margin: 0;
	padding: 0;
	font: normal normal 9px Verdana;
	color: #646260;
	border: 1px solid #CCCCCC;
	border-top: 0;
	background-color: #FFFFFF;
}

#popupCalendar table#monthGrid th {
	text-align: center;
	vertical-align: middle;
	padding: 3px;
	font-weight: bold;
	font-size: 10px;
	color: #646260;
	width: 22px;
	border: 0;
}

#popupCalendar table#monthGrid td {
	text-align: center;
	vertical-align: middle;
	padding: 2px;
	border: 1px solid #CCCCCC;
	font-size: 10px;
	font-weight: normal;
	color: #222222;
	width: 22px;
	height: 3em;

	&.defaultBg {
		background-color: #ffffff;
	}

	&.hightlightedBg {
		background-color: #CCE8EB;
		border-color: #6482AA;

		.hightlightedDate {
			color: #6482AA;
		}
	}

	&.inactiveBg {
		background: #fff url("//d25ckoihpsjafc.cloudfront.net/rcitrials-assets/img/backstripes.gif");
	}

}


