body.l-dialog-open {
	overflow: hidden;
	position: relative;
}
.l-dialog {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	box-sizing: border-box;
	z-index: 10;
	background-color: #000;
	background-color: rgba(0, 0, 0, 0.75);
	padding: 30px;
}
@media (max-width: 767px) {
	.l-dialog {
		padding: 15px;
	}
}
.l-dialog-container-main {
	margin: 200px auto;
	box-sizing: border-box;
	width: 560px;
	background-color: #373737;
	// box-shadow: 0 0 10px #000;
	border-radius: 10px;
	border: 2px solid #696567;
}
@media (max-width: 767px) {
	.l-dialog-container-main {
		width: auto;
	}
}

.l-dialog-container-header {
	position: relative;
	padding: 15px;
	background-color: #EEE;
	border-radius: 5px 5px 0 0;

	.l-dialog-without-container-header & {
		padding: 0;
	}
}
@media (max-width: 767px) {
	.l-dialog-container-header {
		padding: 15px;
	}
}
.l-dialog-title {
	margin: 0;
	font-size: 26px;
	padding-right: 55px;

	.l-dialog-without-container-header & {
		display: none;
	}
}
.l-dialog-close {
	background: url("//d25ckoihpsjafc.cloudfront.net/rcitrials-assets/img/btn_close_popup.png") no-repeat;
	position: absolute;
	top: -11px;
	left: -12px;
	border: 0;
	margin: 0;
	padding: 0;
	cursor: pointer;
	height: 24px;
	width: 24px;

	.l-dialog-without-close-button & {
		display: none;
	}
}
.l-dialog-content-target {
	padding: 15px;
	border-radius: 0 0 5px 5px;
}
@media (max-width: 767px) {
	.l-dialog-content-target {
		padding: 25px 15px;
	}
}
.l-dialog-content-target > :first-child {
	margin-top: 0;
}
.l-dialog-content-target > :last-child {
	margin-bottom: 0;
}
.l-dialog-loading {
	text-align: center;
	font-style: italic;
	color: #373737;
}
.l-dialog-loading:after {
	content: '';
	background: url("//d25ckoihpsjafc.cloudfront.net/rcitrials-assets/img/loader_bigballcircle.gif") no-repeat;
	width: 50px;
	height: 50px;
	display: block;
	margin: 10px auto 10px;
}
