div.headerOverlay{
	position: absolute;
	height: 140px;
	width: 100%;
	margin: 0;
	z-index: 2;
	background: url(@headerOverlayBgImg) no-repeat top center;
}

/* Header Right Side Nav */
.customer-nav-container {
	font-family: Arial;
	color: @customerNavDivColor;
	font-weight: normal;
	text-align: right;
	height: 122px;
	margin: 0;
	padding: 0;
	float: right;

	& .customer-nav-header {
		font-size: 13px;
		font-weight: bold;
		margin: 35px 0 0;
		color: @customerNavDivPrimaryHeaderColor;
	}

	& .customer-nav-user-details,
	& .customer-nav-links {
		font-size: 10px;
		margin: 5px 0 0;

		div {
			&:first-child {
				margin-left: 0;

				&:before {
					content: "";
					padding: 0;
				}
			}

			&:before {
				content: "|";
				padding: 0 10px;
			}
		}
	}

	& .customer-nav-links {
		font-weight: bold;
	}

	& .customer-nav-signin {
		padding: 40px 0;
	}

	& .customer-nav-switch-package {
		margin: 5px 0 0 0;
		a {
			color: #498ce7;
		}
	}

	& a {
		font-size: 11px;
		font-weight: bold;
		text-decoration: none;
		color: @customerNavAColor;

		&:hover {
			text-decoration: underline !important;
		}
	}

	.customer-nav {
		font-family: Arial;
		color: @customerNavDivColor;
		font-weight: normal;
		margin: 0;
		padding: 0;
	}
}

.navPriTopContainer {
	.clearfix();
	padding: 0 20px;
	text-align: center;
	width: 908px;
	border-radius: 5px 5px 5px 5px;
	border: 1px solid @navPriTopAreaBorderColor;
	#gradient .vertical(@navPriTopAreaGradientStart,@navPriTopAreaGradientEnd);
	.box-shadow(0 3px 8px -3px #000000);
	position: relative;
	z-index: 1000;

	& #leftSideMenu {
		float: left;
		width: 680px;
	}

	& #leftSideMenuFull {
		float: left;
		width: 908px;
	}

	& #rightSideMenu {
		margin-top: 6px;
		float: right;
	}

	& p, & div {
		color: @navPriTopNavigationParagraphColor;
	}

	& a.option {
		text-decoration: none;
		text-transform: uppercase;
		font-size: 13px;
		font-family: 'Lato',sans-serif;
		padding-bottom: 6px;
		color: @navPriTopNavigationOptionColor;
		margin: 10px 20px 0 0;
		border-bottom: 3px solid transparent;
		display: block;

		&.on,
		&:hover,
		&:active {
			color: @navPriTopNavigationOptionOnColor;
			border-color: @navPriTopNavigationOptionOnBorderColor;
		}
	}

	// Done to ensure the language select box is positioned
	// consistently across all browsers.
	select {
		margin: 2px 0;
	}
}
