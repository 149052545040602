.referrals-form-container {

	.referrals-header-container {
		margin-bottom: 10px;
	}

	.referrals-header-row {
		.row();

		.column {
			vertical-align: bottom;
			border-bottom: 1px solid #c8c8c8;
			padding-bottom: 2px;

			&:after {
				content: ":";
			}

			&.required {
				&:after {
					content: ":*";
				}
			}
		}
	}

	.referrals-container {
		border-bottom: 1px solid #c8c8c8;
		padding-bottom: 5px;
		margin-bottom: 15px;
	}

	.referrals-row {
		.row();
	}

	.referrals-row-error {
		color: #d31900;
		padding-bottom: 1px;
		vertical-align: top;
	}

	.column {
		vertical-align: top;
		padding-bottom: 8px;
	}

	.col1 {
		width: 150px;
	}
	.col2 {
		width: 150px;
	}
	.col3 {
		width: 150px;
	}
	.col4 {
		width: 248px;
	}

	.referral-form-error {
		color: #d31900;
		margin-bottom: 10px;
	}

	.referral-output-container {
		margin-bottom: 10px;
	}

}
