.my-account-container {

	h3 {
		margin-bottom: 10px;
	}

	.my-account-details {
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid #c8c8c8;

		.detail-container {
			margin-bottom: 3px;
		}

		.label {
			width: 155px;

			&:after {
				content: ":";
			}
		}

		.detail {
			font-weight: bold;
		}
	}

	.my-account-authentication-credentials {
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid #c8c8c8;
	}

	.my-account-user-information {
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid #c8c8c8;

		.contact-information-container,
		.home-address-container,
		.work-address-container {
			width: 275px;
		}

		.state-zip-container {

			.form-field-container {
				&:first-child {
					margin-left: 0;
				}
				margin-left: 10px;
			}
		}
	}

	.my-account-preferences {
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid #c8c8c8;

		.form-field-container {
			&:first-child {
				margin-left: 0;
			}
			margin-left: 20px;
		}
	}

	.my-account-survey {
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid #c8c8c8;

		.my-account-survey-questions {
			width: 850px;

			.survey-question-container {
				width: 410px;
				margin-right: 15px
			}

			.survey-answer-select-container {

				.survey-answer-select {
					margin: 3px 10px 0 0;
					width: 125px;
				}
			}
		}
	}

	.checkbox-container {
		margin-right: 5px;
	}

	.checkbox-label-container {
		margin-top: 2px;
	}

	.terms-and-conditions-container {
		margin-bottom: 20px;

		.agree-to-terms-field-container {
			margin: 20px 0 10px 0;
		}
	}

	.electronic-signature-container {
		.form-field-container {
			&:first-child {
				margin-left: 0;
			}
			margin-left: 30px;
		}
	}

	.alert,
	.alert div {
		color: #d31900;
	}
}
