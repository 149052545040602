/* SEARCH WIDGET STYLES */
#searchWidgetAdvSearchContainer {
	border-radius: 8px 8px 8px 8px;
	margin: 0px 0px 2px 0px;
	min-height: 276px;
	overflow: hidden;
	border: 1px solid #666666;
	.box-shadow(2px 2px 7px 0 #000000);
	background-color: @searchWidgetAdvSearchBgColor;

	& .btn {
		width: 151px;
	}

	& #searchWidgetContainer {
		width: 210px;
		min-height: 237px;
		.box-shadow(2px 2px 7px 0 #000000);
		border-bottom: 1px solid #666666;
		margin: 0;
		padding: 0;
		overflow: hidden;
		.user-select(none);
		#gradient .vertical(@searchWidgetContainerGradientStart,@searchWidgetContainerGradientEnd);

		& #searchWidgetContentsWrapper {

			& #searchWidgetHeader {
				font-family: 'Lato',sans-serif;
				font-size: 17px;
				line-height: 18px;
				padding: 10px 10px 6px;
				color: @searchWidgetHeaderColor;
			}

			& #searchWidgetInstructions {
				font-family: Arial;
				font-size: 11px;
				line-height: 13px;
				padding: 0px 10px 3px;
				color: @searchWidgetTextColor;
			}

		}

		& #searchWidgetExternalContainer {
			margin: 0px;
			padding: 5px 10px 0px 10px;
		}

		& #searchWidgetMonthContainer {
			color: white;
			font-size: 11px;
			position: relative;
			text-align: center;
			width: 210px;
			height: 75px;
			margin: 0;

			& #searchWidgetMonthButtonsContainer {
				left: 6px;
				margin: 4px 0 0 0;
				position: absolute;
				z-index: 5;
			}

			& #searchWidgetNoAvailability {
				font-family: Arial;
				font-size: 11px;
				line-height: 13px;
				position: absolute;
				left: 0;
				height: 65px;
				margin: 0 15px;
				width: 180px;
				z-index: 1;
				text-align: center;
				color: @searchWidgetTextColor;
			}
		}

		& .searchWidgetSelectContainer {
			margin: 0 5px 2px;
			padding: 0;
			color: #D9BC8D;
			position: relative;
			height: 26px;
			width: 198px;

			& .search-widget-select {
				color: #666666;
				display: block;
				font: 11px/14px Arial;
				margin: 4px 5px;
				width: 190px;
				z-index: 5;
				height: 19px;
				text-transform: uppercase;
			}
		}
	}

	& #advancedSearchBtnWrapper {
		padding: 10px 9px;
	}
}

.searchWidgetMonth {
	border-color: #666666;
	border-radius: 4px 4px 4px 4px;
	border-style: solid;
	border-width: 1px;
	color: #ffffff;
	cursor: pointer;
	float: left;
	font-family: Arial;
	font-size: 10px;
	font-weight: bold;
	width: 29px;
	height: 21px;
	margin: 1px;
	padding-top: 6px;
	text-shadow: 0 1px 1px #C47153;
	#gradient .vertical(@searchWidgetMonthGradientStart,@searchWidgetMonthGradientEnd);

	&:hover, &.current {
		cursor: pointer;
		text-shadow: 0 1px 1px #6B328F;
		#gradient .vertical(@searchWidgetMonthOnGradientStart,@searchWidgetMonthOnGradientEnd);
	}

	&.disabled {
		color: #EEE;
		cursor: default;
		border-color: #999999;
		text-shadow: inherit;
		#gradient .vertical(@searchWidgetMonthDisabledGradientStart,@searchWidgetMonthDisabledGradientEnd);
	}
}


/* RESORT SWITCH MONTH STYLES BELOW */

#resortSwitchMonthContainer {
	position: relative;
	font-family: Arial;
	width: 466px;;
	margin-bottom: 15px;
	padding: 0;
	overflow: hidden;
	border-top: 1px solid #c8c8c8;
	border-bottom: 1px solid #c8c8c8;
	padding: 10px 0px;

	.resortSwitchMonthCol1 {
		width: 218px;
		margin: 0 10px 0 20px;
	}

	.resortSwitchMonthCol2 {
		width: 218px;
	}

	#resortSwitchMonthButtonsContainer {
		width: 198px;

		.searchWidgetMonth {
			text-align: center;
			color: #ffffff;
			font-size: 10px;

			&.disabled {
				color: #EEE;
			}

			&.current {
				cursor: default;
			}

			&.col6 {
				width: 29px;
			}
		}
	}

	#resortSwitchMonthCurrMonthYear {
		color: #002b66;
		font-size: 20px;
		line-height: 1.2em;
	}

	.resortSwitchMonthLabel {
		color: #666;
		font-size: 10px;
		margin-bottom: 5px;
	}
	
	#resortSwitchMonthBenefitName {
		color: #498ce7;
		font-size: 12px;
		font-weight: bold;
		margin-top: 8px;
	}
}

