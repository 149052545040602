#map_canvas_pointer {
  width: 466px;
  height: 320px
}
#map_canvas {
  position: static;
  width: 464px;
  height: 320px;
  top: 0px;
  left: 0px;
  border: 1px solid #c8c8c8;
}
#listing {
  width: 466px;
  height: 200px;
  overflow: auto;
  left: 0px;
  top: 15px;
  cursor: pointer;
}
#controls {
  width: 466px;
  top: 0px;
  padding: 0px 0px 5px 0px;
  font-size: 11px;
  position: static;
}
.placeIcon {
  width: 16px;
  height: 16px;
  margin: 2px;
}
.address {
  font-size: 10px;
}
#resultsTable {
  font-size: 10px;
  border-collapse: collapse;
  width: 466px;
  position: static;
}
#locationField {
  width: 466px;
  height: 26px;
  top: 0px;
  left: 0px;
  position: static;
}
#autocomplete {
  width: 460px;
}
.mapWindow {
  font-size: 12px;
  font-weight : bold;
  color : rgb(9, 122, 182);
  font-style : normal;
}