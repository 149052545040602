.privacy-notice {

	h1 {
		margin-bottom: 15px;
	}

	h2 {
		margin: 15px 0 10px;
	}

	.global-terms-scheme {

		.tier-indent-one {
			padding: 0;
			margin: 0 0 0 20px;
		}

		.tier-indent-two {
			padding: 0;
			margin: 0 0 0 60px;
		}

		.tier-indent-three {
			padding: 0;
			margin: 0 0 0 100px;
		}

		.category-label {
			font-size: @font-size-base;
			font-weight: @font-weight-bold;
		}

		.sub-title-label {
			font-size: @font-size-base;
			font-weight: @font-weight-bold;
		}

		.list-scheme {
			margin: 0 0 5px 0;
			padding: 0 !important;

			li {
				font-size: @font-size-base;
				padding: 0 0 5px 0;
				margin: 0;
			}
		}

		p {
			font-size: @font-size-base;
			padding: 0;
			margin: 0 0 10px 0;
		}

		.collection-table {
			display: table;
			width: 90%;
			padding: 0;
			margin: 0 0 20px 0;
			border-bottom: 1px solid @text-color;
			border-collapse: collapse;
			.box-sizing(border-box);

			&:first-child {
				margin-top: 20px;
			}

			.collection-row {
				display: table-row;
				border-top: 1px solid @text-color;
				border-right: 1px solid @text-color;
				width: 100%;
				.box-sizing(border-box);


				.collection-cell {
					display: table-cell;
					border-left: 1px solid @text-color;
					padding: 10px;
					.box-sizing(border-box);

					&.label-cell {
						width: 30%;
					}

					&.description-cell {
						width: 70%;
					}

					p {
						&:last-child {
							margin: 0;
						}
					}
				}
			}
		}
	}
}
