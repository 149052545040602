.resort-slide-show-container {
	padding: 10px;
	border-top: 1px solid #c8c8c8;
}

#resortSlideshow {
	width: 444px;
	height: 355px;
	background-color: #FFF;
	position: relative;
	overflow: hidden;
	border: 1px solid #333333;
	border: 1px solid rgba(34,34,34,0.5);

	& #controlsWrapper {
		width: 444px;
		height: 35px;
		background-color: #333333;
		background-color: rgba(34,34,34,0.75);
		position: absolute;
		z-index: 10;

		& #controlsOverflow {
			position: relative;
			margin: 0 33px;
			width: 378px;
			height: 100%;
			overflow: hidden;

			& #controlsContainer {
				position: relative;
				top: 0;
				left: 0;
				width: 100%;
				height: 35px;
				overflow: hidden;
				-webkit-transition: left .25s ease 0s;
				-moz-transition: left .25s ease 0s;
				-ms-transition: left .25s ease 0s;
				-o-transition: left .25s ease 0s;
				transition: left .25s ease 0s;

				& div.control {
					display: inline-block;
					cursor: pointer;
					width: 21px;
					height: 35px;

					& p {
						font-family: Arial;
						color: #FFF;
						margin: 0;
						text-align: center;
						font-size: 10px;
						line-height: 35px;
					}
				}

				& div.basicSlideshowControl {

					&:hover {
						background-color: rgba(255,255,255,.25);
					}

					&.on, 
					&.on:hover {
						background-color: rgba(255,255,255,.5);
					}
				}
			}
		}
	}

	& #slidesContainer {
		width: 100%;
		height: 100%;
		position: relative;

		& div {
			background-repeat: no-repeat;
			width: 100%;
			height: 100%;
		}
	}

	& #resortSlideshowBadge {
		position: absolute;
		bottom: 5px;
		left: 5px;
	}

	& .control-btn {
		cursor: pointer;
		width: 21px;
		min-width: 21px;
		height: 36px;
		min-height: 36px;
		position: absolute;
		top: -2.5px;
		z-index: 11;
		background-repeat: no-repeat;
	}

	#controlLeft {
		background-image: url("//d25ckoihpsjafc.cloudfront.net/rcitrials-assets/img/resortSlideshow/left.png");
		left: 9px;
		display: none;
	}
	
	#controlRight {
		background-image: url("//d25ckoihpsjafc.cloudfront.net/rcitrials-assets/img/resortSlideshow/right.png");
		right: 9px;
	}
}
