.customer-packages-container {

	.customer-packages-title {
		font-family: 'Lato',sans-serif;
		font-size: 20px;
		color: #ffffff;
		padding: 0px;
		margin: 3px 0 15px 0;
		text-align: left;
	}

	.customer-packages-content {
		font-family: Arial;
    font-size: 11px;
    font-weight: normal;
    color: #aaaaaa;
    padding: 0px;
    margin: 0px;
    text-align: left;
	}

	.customer-packages-table {
		width: 100%;
		color: #ffffff;
		font-size: 11px;
		font-family: Verdana;
		margin: 12px 0 15px 0;
		text-align: left;
		border-spacing: 0;
		border-collapse: collapse;

		.customer-packages-header {
			> th {
				border-top: 1px solid #ffffff;
				border-bottom: 1px solid #ffffff;
				padding-top: 5px;
				padding-bottom: 5px;
				padding-right: 10px;
				vertical-align: bottom;
			}
		}

		& tr.customer-package-details-selected {
			color: #498ce7;
		}

		& tr.customer-package-details-expired {
			color: #ffce3d;
		}

		& tr.customer-package-details-deactivated {
			color: #d31900;
		}

		& tr.customer-package-details-pending {
			color: #00d319;
		}

		td {
				padding: 8px 0;
				padding-right: 10px;
				border-bottom: 1px solid #666666;
				vertical-align: top;
		}

		.package-name {

		}

		.membership-number {

		}

		.expiration-date {

		}

	}
}
